.App {
  text-align: center;
}


a {
  text-decoration: none;
  color: black;
  cursor: text;
  /* pointer-events: none; */
}